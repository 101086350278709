<template>
<el-dialog v-model="visible" :title="form.title" :before-close="close" :append-to-body="true" custom-class="proconfig-rule-dialog" :close-on-click-modal="false">
  <el-form :model="form" :rules="rules" ref="ruleFormRef" label-width="100px" v-loading="form.loading">
    <el-form-item label="所属省份" prop="txt_area_name">
      <el-input v-model="form.txt_area_name" :disabled="true" />
    </el-form-item>
    <el-form-item label="省代姓名" prop="txt_realname">
      <el-input v-model="form.txt_realname" :disabled="true" />
    </el-form-item>
    <el-form-item label="设备类型" prop="txt_type_name">
      <el-input v-model="form.txt_type_name" :disabled="true" />
    </el-form-item>
    <el-form-item label="方案名称" prop="txt_tp_name">
      <el-input v-model="form.txt_tp_name" :disabled="true" />
    </el-form-item>
    <el-form-item label="方案金额" prop="dec_tp_fee_amount">
      <el-input v-model="form.dec_tp_fee_amount" @blur="calculateFn" @input="handleInput($event, 'dec_tp_fee_amount', null)" />
    </el-form-item>
    <el-form-item label="省代分润" prop="dec_province_split_amount">
      <el-input v-model="form.dec_province_split_amount" @blur="calculateFn" @input="handleInput($event, 'dec_province_split_amount', null)" />
    </el-form-item>
    <el-form-item label="市代分润" prop="dec_city_split_amount">
      <el-input v-model="form.dec_city_split_amount" @blur="calculateFn" @input="handleInput($event, 'dec_city_split_amount', null)" />
    </el-form-item>
    <el-form-item label="业务员分润" prop="dec_salesman_split_amount">
      <el-input v-model="form.dec_salesman_split_amount" @blur="calculateFn" @input="handleInput($event, 'dec_salesman_split_amount', null)" />
    </el-form-item>
    <el-form-item label="诊所分润" prop="dec_clinic_split_amount">
      <el-input v-model="form.dec_clinic_split_amount" @blur="calculateFn" @input="handleInput($event, 'dec_clinic_split_amount', null)" />
    </el-form-item>
    <el-form-item label="返还类型" prop="txt_return_type">
      <el-select v-model="form.txt_return_type" @change="handleSelect()">
        <!-- 不返还情况 字段不传 返还input也不传 -->
        <el-option label="不返还" value="0" />
        <el-option label="实时返还" value="r" />
        <el-option label="累计返还" value="m" />
      </el-select>
    </el-form-item>
    <el-form-item label="返还" prop="dec_return_amount" v-if="form.txt_return_type!='0'">
      <el-input v-model="form.dec_return_amount" @blur="calculateFn" @input="handleInput($event, 'dec_return_amount', null)" />
    </el-form-item>
    <el-form-item label="联检返还" prop="txt_return_agent_level">
      <el-table :data="table.ruleTableData" v-loading="table.ruleTabelLoading">
        <el-table-column prop="txt_type_name" label="设备类型" align="center" />
        <el-table-column prop="txt_tp_name" label="方案名称" align="center" />
        <el-table-column class-name="pcrd-table-option" prop="dec_divide_amount" label="返还金额" align="center">
          <template #default="scope">
            <div class="pctdto-input">
              <div class="table-rule-icon">*</div>
              <el-input v-model="scope.row.dec_divide_amount" @blur="calculateFn" @input="handleInput($event, 'dec_divide_amount', scope.row)" />
            </div>
            <div v-if="scope.row.required" class="pctdto-hint">请输入返还金额</div>
          </template>
        </el-table-column>
      </el-table>
    </el-form-item>
    <el-form-item label="公司留存" prop="dec_company_profit_amount">
      <el-input v-model="form.dec_company_profit_amount" :disabled="true" />
    </el-form-item>
  </el-form>
  <template #footer>
    <span class="dialog-footer">
      <el-button @click="close('delete')" v-if="form.txt_pricing_type!='c'">删除</el-button>
      <el-button :disabled="confirmBtnDisabled" type="primary" @click="close('confirm')">确定</el-button>
    </span>
  </template>
</el-dialog>
</template>

<script>
// 省代配置 - 分润规则配置
import { useStore } from 'vuex';
import { onMounted, reactive, toRefs, ref } from 'vue';
import { ElMessage, FormInstance, ElMessageBox } from 'element-plus';
import { getDivideRuleById, createDevideRuleById, updatetDivideConfigByRuleId, deleteTpAndDivide } from "api/apis.js";
export default {
  props: {
    ruleData: { type: Object, default: function () { return {} } },
  },
  setup(prop, ctx) {
    const store = useStore();
    let moment = require("moment");
    const numPattern = (rule, value, callback) => {
      const reg = /^([1-9]\d*(\.\d*[1-9][0-9])?)|(0\.\d*[1-9][0-9])|(0\.\d*[1-9])$/;
      if (reg.test(parseFloat(value))) {
        return callback();
      } else {
        callback(new Error("分润金额必须大于0"))
      }
    };

    const state = reactive({
      ruleFormRef: ref(FormInstance),
      form: {
        title: "更新 适用分润规则"
      },
      visible: true,
      table: {
        ruleTableData: [],
        ruleTabelLoading: false,
      },
      rules: {
        dec_tp_fee_amount: [{ required: true, message: "请输入方案金额", trigger: "blur" }],
        dec_province_split_amount: [
          { required: true, message: "请输入省代分润", trigger: "blur" },
          { required: true, validator: numPattern, trigger: "blur" }
        ],
        dec_city_split_amount: [
          { required: true, message: "请输入市代分润", trigger: "blur" },
          { required: true, validator: numPattern, trigger: "blur" }
        ],
        dec_salesman_split_amount: [
          { required: true, message: "请输入业务员分润", trigger: "blur" },
          { required: true, validator: numPattern, trigger: "blur" }
        ],
        dec_clinic_split_amount: [
          { required: true, message: "请输入诊所分润", trigger: "blur" },
          { required: true, validator: numPattern, trigger: "blur" }
        ],
        dec_return_amount: [{ required: true, message: "请输入返还金额", trigger: "blur" }],
        txt_return_type: [{ required: true, message: "请选择返还类型", trigger: "blur" }]
      },
      confirmBtnDisabled: false
    });

    onMounted(() => {
      state.table.ruleTableData = [];
      state.form.txt_area_name = prop.ruleData.txt_area_name;
      state.form.txt_realname = prop.ruleData.txt_realname;
      state.form.txt_pricing_type = prop.ruleData.txt_pricing_type;
      state.form.txt_tp_name = prop.ruleData.txt_tp_name;
      state.form.txt_type_name = prop.ruleData.txt_type_name;
      state.form.title = (prop.ruleData.txt_pricing_type == "c" ? "新增 " : "更新 ") + prop.ruleData.txt_realname + " " + prop.ruleData.txt_type_name + " 适用分润规则";
      getRuleData();
    })

    // 加载分润规则 + 配置
    const getRuleData = () => {
      state.form.loading = true;
      state.table.ruleTabelLoading = true;
      getDivideRuleById({
        rule_id: prop.ruleData.t_divide_rule_id
      }).then(response => {
        if (response.code == 200) {
          state.form = { ...state.form, ...response.data };
          for (let i = 0; i < Object.keys(state.form).length; i++) {
            let key = Object.keys(state.form)[i];
            if (key != "txt_return_type" && state.form[key] == null) state.form[key] = "0.00";
            if (key == "txt_return_type" && state.form[key] == null) state.form[key] = "0";
          }
          // 插入必填校验 标识字段
          if ((response.data.combined || []).length != 0) {
            response.data.combined.forEach(item => {
              item.required = false;
              item.dec_divide_amount = item.dec_divide_amount ? item.dec_divide_amount : "0.00"
            });
          }
          state.table.ruleTableData = response.data.combined;
          calculateFn();
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.form.loading = false;
        state.table.ruleTabelLoading = false;
      })
    };

    // 金额格式化
    const handleInput = (event, key, row) => {
      let target = event.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1'); // 只能输入数字小数，两位
      target = target.replace(/^(0{1,})(\d{1,}.*)/, '$2'); // 去除开头的多余的0
      if (row == null) {
        state.form[key] = target;
      } else {
        row[key] = target;
      }
    };

    // select 选择器 改变事件
    const handleSelect = () => {
      if (state.form.txt_return_type == "0") {
        calculateFn(0);
      } else {
        calculateFn(state.form.dec_return_amount);
      }
    };

    // 计算公司留存
    const calculateFn = (num) => {
      if (typeof num == "object") {
        if (num.target.value.includes(".")) {
          let tempTarget = num.target.value.split(".");
          if (tempTarget[1] == "") {
            num.target.value = num.target.value + "00";
          } else if (tempTarget[1].length == 1) {
            num.target.value = num.target.value + "0";
          }
        } else if (num.target.value != "") {
          num.target.value = num.target.value + ".00";
        }
      }
      if (state.form.txt_return_type == "0") num = 0;
      let dec_return_amount = num == 0 ? 0 : state.form.dec_return_amount;
      let arr = [parseFloat(state.form.dec_province_split_amount || "0"), parseFloat(state.form.dec_city_split_amount || "0"), parseFloat(state.form.dec_salesman_split_amount || "0"), parseFloat(state.form.dec_clinic_split_amount || "0"), parseFloat(dec_return_amount || "0")],
        dec_tp_fee_amount = parseFloat(state.form.dec_tp_fee_amount);
      if ((state.table.ruleTableData || []).length > 0) arr = arr.concat(state.table.ruleTableData.map(t => parseFloat(t.dec_divide_amount || "0")));
      for (let i = 0; i < arr.length; i++) {
        dec_tp_fee_amount = parseFloat((dec_tp_fee_amount - arr[i]).toFixed(2));
      }
      state.form.dec_company_profit_amount = dec_tp_fee_amount.toFixed(2);
      if (parseFloat(state.form.dec_company_profit_amount) == parseFloat("0.00")) {
        ElMessageBox.confirm(
          `返还后公司利润为零，请检查数据`,
          '提示', {
            distinguishCancelAndClose: true,
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }
        ).then(() => {})
        state.confirmBtnDisabled = true;
      } else if (parseFloat(state.form.dec_company_profit_amount) < parseFloat("0.00")) {
        ElMessageBox.confirm(
          `公司利润有误，不能为负数，请检查数据`,
          '提示', {
            distinguishCancelAndClose: true,
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }
        ).then(() => {})
        state.confirmBtnDisabled = true;
      } else {
        state.confirmBtnDisabled = false;
      }
    };

    // 关闭事件
    const close = (type = false) => {
      if (type == "confirm") {
        if (parseFloat(state.form.dec_company_profit_amount) == parseFloat("0.00")) {
          ElMessageBox.confirm(
            `返还后公司利润为零，请检查数据`,
            '提示', {
              distinguishCancelAndClose: true,
              confirmButtonText: '确定',
              cancelButtonText: '取消',
            }
          ).then(() => {})
          state.confirmBtnDisabled = true;
        } else if (parseFloat(state.form.dec_company_profit_amount) < parseFloat("0.00")) {
          ElMessageBox.confirm(
            `公司利润有误，不能为负数，请检查数据`,
            '提示', {
              distinguishCancelAndClose: true,
              confirmButtonText: '确定',
              cancelButtonText: '取消',
            }
          ).then(() => {})
          state.confirmBtnDisabled = true;
        } else {
          state.confirmBtnDisabled = false;
          // 启动必填校验
          state.ruleFormRef.validate(valid => {
            if (valid) {
              if ((state.table.ruleTableData || []).length != 0) {
                // 启动表格必填校验
                let tempArr = state.table.ruleTableData.map(t => t.dec_divide_amount);
                for (let i = 0; i < tempArr.length; i++) {
                  if ((tempArr[i] || "") == "") {
                    state.table.ruleTableData[i].required = true;
                  } else {
                    state.table.ruleTableData[i].required = false;
                  }
                }
                // 没有必填校验标注
                // 调用保存接口，判断是新增还是更新
                if (state.table.ruleTableData.findIndex(t => t.required == true) == -1) {
                  state.form.loading = true;
                  // 调用接口 所需参数
                  let defaultRule = JSON.parse(localStorage.getItem("defaultRule")),
                    param = {
                      rule_id: state.form.t_divide_rule_id,
                      agentid: defaultRule.t_agent_id,
                      fee: state.form.dec_tp_fee_amount,
                      level1splitamount: state.form.dec_province_split_amount,
                      level2splitamount: state.form.dec_city_split_amount,
                      level3splitamount: state.form.dec_salesman_split_amount,
                      level4splitamount: state.form.dec_clinic_split_amount,
                      splittype: state.form.txt_return_type,
                      returnamount: state.form.dec_return_amount,
                      combined: state.table.ruleTableData.map(t => {
                        return {
                          txt_tp_name: t.txt_tp_name,
                          device_type_name: t.txt_type_name,
                          returnamount: t.dec_divide_amount,
                          t_divide_rule_id: t.t_divide_rule_id
                        }
                      })
                    };
                  // 如果 返还类型 是 “不返还”， 则不传 返还类型 和 返还 字段
                  if (param.splittype == "0") {
                    delete param.splittype;
                    delete param.returnamount;
                  }
                  // 如果不是'c'，为更新
                  if (state.form.txt_pricing_type != 'c') {
                    param.txt_updater = store.getters.userInfo.name || "系统用户";
                    param.dt_update = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
                    updatetDivideConfigByRuleId(param).then(response => {
                      if (response.code == 200) {
                        ElMessage.success("操作成功");
                        ctx.emit("close", "fresh");
                      } else {
                        ElMessage.error("操作失败");
                      }
                    }).catch(e => {
                      console.error(e);
                    }).finally(() => {
                      state.form.loading = false;
                    })
                  } else {
                    // 新增
                    param.txt_creator = store.getters.userInfo.name || "系统用户";
                    param.dt_create = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
                    createDevideRuleById(param).then(response => {
                      if (response.code == 200) {
                        ElMessage.success("操作成功");
                        ctx.emit("close", "fresh");
                      } else {
                        ElMessage.error("操作失败");
                      }
                    }).catch(e => {
                      console.error(e);
                    }).finally(() => {
                      state.form.loading = false;
                    })
                  }
                }
              } else {
                // 存在联检情况
                state.form.loading = true;
                // 调用接口 所需参数
                let defaultRule = JSON.parse(localStorage.getItem("defaultRule")),
                  param = {
                    rule_id: state.form.t_divide_rule_id,
                    agentid: defaultRule.t_agent_id,
                    fee: state.form.dec_tp_fee_amount,
                    level1splitamount: state.form.dec_province_split_amount,
                    level2splitamount: state.form.dec_city_split_amount,
                    level3splitamount: state.form.dec_salesman_split_amount,
                    level4splitamount: state.form.dec_clinic_split_amount,
                    splittype: state.form.txt_return_type,
                    returnamount: state.form.dec_return_amount,
                    // combined: state.table.ruleTableData.map(t => {
                    //   return {
                    //     txt_tp_name: t.txt_tp_name,
                    //     device_type_name: t.txt_type_name,
                    //     returnamount: t.dec_divide_amount,
                    //     t_divide_rule_id: t.t_divide_rule_id
                    //   }
                    // })
                  };
                // 如果 返还类型 是 “不返还”， 则不传 返还类型 和 返还 字段
                if (param.splittype == "0") {
                  delete param.splittype;
                  delete param.returnamount;
                }
                // 如果不是'c'，为更新
                if (state.form.txt_pricing_type != 'c') {
                  param.txt_updater = store.getters.userInfo.name || "系统用户";
                  param.dt_update = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
                  updatetDivideConfigByRuleId(param).then(response => {
                    if (response.code == 200) {
                      ElMessage.success("操作成功");
                      ctx.emit("close", "fresh");
                    } else {
                      ElMessage.error("操作失败");
                    }
                  }).catch(e => {
                    console.error(e);
                  }).finally(() => {
                    state.form.loading = false;
                  })
                } else {
                  // 新增
                  param.txt_creator = store.getters.userInfo.name || "系统用户";
                  param.dt_create = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
                  createDevideRuleById(param).then(response => {
                    if (response.code == 200) {
                      ElMessage.success("操作成功");
                      ctx.emit("close", "fresh");
                    } else {
                      ElMessage.error("操作失败");
                    }
                  }).catch(e => {
                    console.error(e);
                  }).finally(() => {
                    state.form.loading = false;
                  })
                }
              }
            }
          })
        }

      } else {
        if (type != "delete") {
          ctx.emit("close", 'close');
        } else {
          // 删除
          state.form.loading = true;
          deleteTpAndDivide({
            rule_id: state.form.t_divide_rule_id,
            txt_updater: store.getters.userInfo.name || "系统用户",
            dt_update: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
          }).then(response => {
            if (response.code == 200) {
              ElMessage.success("删除成功");
              ctx.emit("close", 'fresh');
            } else {
              ElMessage.error(response.msg);
            }
          }).catch(e => {
            console.error(e);
          }).finally(() => {
            state.form.loading = false;
          })
        }
      }
    };

    return {
      ...toRefs(state),
      close,
      getRuleData,
      calculateFn,
      handleInput,
      handleSelect
    }
  }
}
</script>

<style lang="scss">
.proconfig-rule-dialog {
  width: 800px;

  .pcrd-table-option {
    .cell {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .pctdto-input {
        display: flex;
        flex-direction: row;
        align-items: center;

        .table-rule-icon {
          color: #f57272;
          margin-right: 4px;
        }
      }

      .pctdto-hint {
        margin-left: 7px;
        color: #f57272;
      }
    }
  }
}
</style>
