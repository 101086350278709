<template>
<div id="page" class="pro-config-index row-start-start">
  <div class="pci-table-left">
    <el-table @row-click="getDatas" :data="table.mainTableData" v-loading="table.mainTabelLoading" :row-class-name="getMainRowClass">
      <el-table-column prop="txt_realname" label="省代姓名" align="center" />
      <el-table-column prop="txt_mobile" label="联系电话" align="center" width="120">
        <template #default="scope">{{scope.row.txt_mobile || "- -"}}</template>
      </el-table-column>
      <el-table-column prop="txt_verify_status" label="审核状态" align="center" width="80" />
      <el-table-column prop="dt_verify" label="审批时间" align="center" width="170">
        <template #default="scope">{{scope.row.dt_verify || "- -"}}</template>
      </el-table-column>
      <el-table-column prop="txt_agent_type" label="类型" align="center" width="80" />
      <el-table-column prop="txt_default_receipt_account" label="默认收款账户" align="center" width="150">
        <template #default="scope">{{scope.row.txt_default_receipt_account || "- -"}}</template>
      </el-table-column>
    </el-table>
  </div>
  <div class="pci-table-right">
    <div class="pcitr-config">
      <div class="pcitr-title">申领及返还配置</div>
      <el-table @row-click="updateConfig" :data="table.configTableData" v-loading="table.configTabelLoading" :row-class-name="getRowClass">
        <el-table-column prop="txt_agent_config_type_desc" label="配置类型" align="center" width="80" />
        <el-table-column prop="txt_area_name" label="所属地区" align="center" />
        <el-table-column prop="txt_realname" label="代理姓名" align="center" />
        <el-table-column prop="txt_type_name" label="代理设备" align="center" />
        <el-table-column prop="dec_agent_amount" label="返还上限" align="center">
          <template #default="scope">
            <template v-if="scope.row.dec_agent_amount != null">￥{{scope.row.dec_agent_amount}}</template>
            <template v-else>- -</template>
          </template>
        </el-table-column>
        <el-table-column prop="txt_return_agent_level" label="返还级别" align="center" width="80" />
        <el-table-column prop="txt_can_apply" label="能否申领" align="center" width="80">
          <template #default="scope">
            <template v-if="scope.row.is_can_apply == 1">是</template>
            <template v-else>否</template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pcitr-apply-rule">
      <div class="pcitr-title margin-top">适用分润规则</div>
      <el-table @row-click="updateRule" :data="table.ruleTableData" v-loading="table.ruleTabelLoading" :row-class-name="getRowClass">
        <el-table-column prop="txt_type_name" label="代理设备" align="center" />
        <el-table-column prop="txt_tp_name" label="方案名称" align="center" />
        <el-table-column prop="txt_pricing_target" label="适用范围" align="center" />
        <el-table-column prop="dec_tp_fee_amount" label="方案价格" align="center">
          <template #default="scope">
            <template v-if="scope.row.dec_tp_fee_amount != null">￥{{scope.row.dec_tp_fee_amount}}</template>
            <template v-else>- -</template>
          </template>
        </el-table-column>
        <el-table-column prop="dec_level_1_split_amount" label="省代分润" align="center">
          <template #default="scope">
            <template v-if="scope.row.dec_level_1_split_amount != null">￥{{scope.row.dec_level_1_split_amount}}</template>
            <template v-else>- -</template>
          </template>
        </el-table-column>
        <el-table-column prop="dec_level_2_split_amount" label="市代分润" align="center">
          <template #default="scope">
            <template v-if="scope.row.dec_level_2_split_amount != null">￥{{scope.row.dec_level_2_split_amount}}</template>
            <template v-else>- -</template>
          </template>
        </el-table-column>
        <el-table-column prop="dec_level_3_split_amount" label="业务员分润" align="center">
          <template #default="scope">
            <template v-if="scope.row.dec_level_3_split_amount != null">￥{{scope.row.dec_level_3_split_amount}}</template>
            <template v-else>- -</template>
          </template>
        </el-table-column>
        <el-table-column prop="dec_level_4_split_amount" label="诊所分润" align="center">
          <template #default="scope">
            <template v-if="scope.row.dec_level_4_split_amount != null">￥{{scope.row.dec_level_4_split_amount}}</template>
            <template v-else>- -</template>
          </template>
        </el-table-column>
        <el-table-column prop="dec_return_amount" label="返还" align="center">
          <template #default="scope">
            <template v-if="scope.row.dec_return_amount != null">￥{{scope.row.dec_return_amount}}</template>
            <template v-else>- -</template>
          </template>
        </el-table-column>
        <el-table-column prop="dec_company_profit_amount" label="公司利润" align="center">
          <template #default="scope">
            <template v-if="scope.row.dec_company_profit_amount != null">￥{{scope.row.dec_company_profit_amount}}</template>
            <template v-else>- -</template>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
  <config v-if="dialog.configShow" :configData="dialog.configData" @close="(e)=>{configClose(e)}" />
  <rule v-if="dialog.ruleShow" :ruleData="dialog.ruleData" @close="(e) => {ruleClose(e)}" />
</div>
</template>

<script>
// 总账管理 - 省代配置
import { ElMessage } from 'element-plus';
import Config from "./comps/config.vue";
import Rule from "./comps/rule.vue";
// getCurrentInstance, 
import { onMounted, reactive, toRefs } from 'vue';
import { getProvincePartners, getAgentConfigByAgentId, getDivideConfigByAgentId } from "api/apis.js";
export default {
  components: {
    Config,
    Rule
  },
  setup() {
    // const instance = getCurrentInstance();
    // let {ctx:that, proxy} = getCurrentInstance()
    const state = reactive({
      table: {
        mainTableData: [],
        mainTabelLoading: false,
        configTableData: [],
        configTabelLoading: false,
        ruleTableData: [],
        ruleTabelLoading: false,
      },
      dialog: {
        configData: {},
        configShow: false,
        ruleData: {},
        ruleShow: false,
      }
    });

    onMounted(() => {
      getProvincePartnersList();
    });

    // 分别加载 申领及返还配置 + 适用分润规则
    const getDatas = row => {
      row.focus = true;
      let lastFocusRow = JSON.parse(localStorage.getItem("lastFocusRow"));
      if (lastFocusRow && lastFocusRow.t_agent_id != row.t_agent_id) {
        let tempIndex = state.table.mainTableData.findIndex(t => t.t_agent_id == lastFocusRow.t_agent_id);
        state.table.mainTableData[tempIndex].focus = false;
      }
      // instance.ctx.$forceUpdate();
      getConfigData(row);
      getRuleData(row);
      localStorage.setItem("defaultRule", JSON.stringify(row));
      state.dialog.ruleData.txt_realname = row.txt_realname;
      state.dialog.ruleData.txt_area_name = row.txt_area_name;
      localStorage.setItem("lastFocusRow", JSON.stringify(row));
    };

    // 设置 省代 表格 行样式
    const getMainRowClass = ({ row }) => {
      if (row.focus) {
        return "pro-main-row-class";
      } else {
        return "";
      }
    };

    // 获取 配置表格 样式
    const getRowClass = ({ row }) => {
      if (row.txt_agent_config_type == "p" || row.txt_pricing_type == "a") {
        return "personal-cell-class";
      } else {
        return "";
      }
    };

    // 新增申领及返还配置
    const updateConfig = row => {
      state.dialog.configData = row;
      state.dialog.configShow = true;
    };

    // 新增分润配置
    const updateRule = row => {
      state.dialog.ruleData = { ...row, ...state.dialog.ruleData };
      state.dialog.ruleShow = true;
    };

    // 配置 窗口关闭事件
    const configClose = e => {
      state.dialog.configShow = false;
      if (e == "fresh") {
        let defaultRule = JSON.parse(localStorage.getItem("defaultRule"));
        getConfigData(defaultRule);
      }
    };

    // 分润规则 dialog 关闭事件
    const ruleClose = (e) => {
      state.dialog.ruleShow = false;
      let defaultRule = JSON.parse(localStorage.getItem("defaultRule"));
      state.dialog.ruleData = {};
      state.dialog.ruleData.txt_realname = defaultRule.txt_realname;
      state.dialog.ruleData.txt_area_name = defaultRule.txt_area_name;
      if (e == "fresh") {
        getRuleData(defaultRule);
      }
    };

    // 根据省代ID 获取 适用分润规则
    const getRuleData = row => {
      state.table.ruleTabelLoading = true;
      getDivideConfigByAgentId({
        t_agent_id: row.t_agent_id
      }).then(response => {
        if (response.code == 200) {
          state.table.ruleTableData = response.data;
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.table.ruleTabelLoading = false;
      })
    };

    // 根据省代ID获取申领及返还配置
    const getConfigData = row => {
      state.table.configTabelLoading = true;
      getAgentConfigByAgentId({
        t_agent_id: row.t_agent_id
      }).then(response => {
        if (response.code == 200) {
          state.table.configTableData = response.data;
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.table.configTabelLoading = false;
      })
    };

    // 获取省代合伙人
    const getProvincePartnersList = () => {
      state.table.mainTabelLoading = true;
      getProvincePartners().then(response => {
        if (response.code == 200) {
          state.table.mainTableData = response.data;
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.table.mainTabelLoading = false;
      })
    };
    return {
      ...toRefs(state),
      getConfigData,
      getDatas,
      getRuleData,
      updateConfig,
      updateRule,
      ruleClose,
      configClose,
      getRowClass,
      getMainRowClass
    }
  }
}
</script>

<style lang="scss">
.pro-config-index {
  .pci-table-left {
    height: 100%;
    width: 45%;
    border-right: 1px solid #ebeef5;
    padding-right: 6px;

    .el-table {
      height: 100%;
    }
  }

  .el-table {
    tbody {
      .personal-cell-class:hover>td {
        background-color: #ffa07a !important;
      }

      .pro-main-row-class:hover>td {
        background-color: #89c3f0 !important;
      }
    }
    .el-table__row {
      cursor: pointer;

      &.personal-cell-class {
        background-color: #ffa07a !important;
      }

      &.pro-main-row-class {
        background-color: #89c3f0 !important;
      }
    }
  }

  .pci-table-right {
    padding-left: 6px;
    width: 55%;
    height: 100%;

    .pcitr-title {
      margin-bottom: 6px;

      &.margin-top {
        margin-top: 6px;
      }
    }

    .pcitr-config {
      height: 40%;

      .el-table {
        width: 100%;
        height: calc(100% - 30px);
      }
    }

    .pcitr-apply-rule {
      height: calc(60% - 6px);

      .el-table {
        width: 100%;
        height: calc(100% - 40px);
      }
    }
  }
}
</style>
