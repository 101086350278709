<template>
<el-dialog v-model="visible" :title="form.title" :before-close="close" :append-to-body="true" custom-class="proconfig-config-dialog" :close-on-click-modal="false">
  <el-form :model="form" v-loading="loading" :rules="rules" ref="ruleFormRef" label-width="140px">
    <el-form-item label="所属省份" prop="txt_area_name">
      <el-input v-model="form.txt_area_name" :disabled="true" />
    </el-form-item>
    <el-form-item label="省代姓名" prop="txt_realname">
      <el-input v-model="form.txt_realname" :disabled="true" />
    </el-form-item>
    <el-form-item label="设备类型" prop="txt_type_name">
      <el-input v-model="form.txt_type_name" :disabled="true" />
    </el-form-item>
    <el-form-item label="返还上限" prop="dec_agent_amount">
      <el-input v-model="form.dec_agent_amount" @blur="handleBlur" @input="handleInput($event)" />
    </el-form-item>
    <el-form-item label="返还级别" prop="txt_return_agent_level">
      <el-select v-model="form.int_return_agent_level">
        <el-option v-for="(item, index) in options.returnAgentLevelOption" :key="index" :label="item.name" :value="item.int_return_agent_level" />
      </el-select>
    </el-form-item>
    <el-form-item label="是否支持业务员申领" prop="is_can_apply">
      <el-select v-model="form.is_can_apply">
        <el-option label="是" :value="1" />
        <el-option label="否" :value="0" />
      </el-select>
    </el-form-item>
  </el-form>
  <template #footer>
    <span class="dialog-footer">
      <el-button @click="close('delete')" v-if="form.txt_agent_config_type != 'd'">删除</el-button>
      <el-button type="primary" @click="close('confirm')">确定</el-button>
    </span>
  </template>
</el-dialog>
</template>

<script>
// 省代配置 - 申领及返还配置
import { useStore } from 'vuex';
import { ElMessage, FormInstance } from 'element-plus';
import { onMounted, reactive, toRefs, ref } from 'vue';
import { createAgentConfig, updateAgentConfig, deleteAgentConfig } from "api/apis.js";
export default {
  props: {
    configData: { type: Object, default: function () { return {} } },
  },
  setup(prop, ctx) {
    const store = useStore();
    let moment = require("moment");
    const state = reactive({
      form: {
        title: "更新 申领及返还配置"
      },
      ruleFormRef: ref(FormInstance),
      loading: false,
      options: {
        returnAgentLevelOption: [
          { int_return_agent_level: 0, name: "不返还" },
          { int_return_agent_level: 1, name: "省代" },
          { int_return_agent_level: 2, name: "市代" },
          { int_return_agent_level: 3, name: "业务员" },
          { int_return_agent_level: 4, name: "诊所" }
        ]
      },
      visible: true,
      rules: {
        dec_agent_amount: [{ required: true, message: "请输入返还上限", trigger: "blur" }],
      }
    });

    onMounted(() => {
      state.form = prop.configData;
      state.form.title = (prop.configData.txt_agent_config_type == "d" ? "新增 " : "更新 ") + prop.configData.txt_realname + " " + prop.configData.txt_type_name + " 申领及返还配置"
    });

    // 关闭事件
    const close = (type = 'close') => {
      if (type == "confirm") {
        state.ruleFormRef.validate(valid => {
          if (valid) {
            state.loading = true;
            // 判断是 更新还是新增
            if (state.form.txt_agent_config_type != "d") {
              // 更新
              updateAgentConfig({
                configId: state.form.t_agent_config_id,
                agentAmount: state.form.dec_agent_amount,
                returnLevel: state.form.int_return_agent_level,
                canApply: state.form.is_can_apply,
                txt_updater: store.getters.userInfo.name || "系统用户",
                dt_update: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
              }).then(response => {
                if (response.code == 200) {
                  ElMessage.success("操作成功");
                  ctx.emit("close", 'fresh');
                } else {
                  ElMessage.error(response.msg);
                }
              }).catch(e => {
                console.error(e);
              }).finally(() => {
                state.loading = false;
              })
            } else {
              // 新增
              createAgentConfig({
                agentId: state.form.t_configured_agent_id,
                deviceTypeId: state.form.t_device_type_id,
                agentAmount: state.form.dec_agent_amount,
                returnLevel: state.form.int_return_agent_level,
                canApply: state.form.is_can_apply,
                txt_creator: store.getters.userInfo.name || "系统用户",
                dt_create: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
              }).then(response => {
                if (response.code == 200) {
                  ElMessage.success("操作成功");
                  ctx.emit("close", 'fresh');
                } else {
                  ElMessage.error(response.msg);
                }
              }).catch(e => {
                console.error(e);
              }).finally(() => {
                state.loading = false;
              })
            }
          }
        })
      } else {
        if (type != "delete") {
          ctx.emit("close", 'close');
        } else {
          state.loading = true;
          deleteAgentConfig({
            configId: state.form.t_agent_config_id,
            txt_updater: store.getters.userInfo.name || "系统用户",
            dt_update: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
          }).then(response => {
            if (response.code == 200) {
              ElMessage.success("删除成功");
              ctx.emit("close", 'fresh');
            } else {
              ElMessage.error(response.msg);
            }
          }).catch(e => {
            console.error(e);
          }).finally(() => {
            state.loading = false;
          })
        }
      }
    };

    // 金额格式化
    const handleInput = event => {
      let target = event.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1'); // 只能输入数字小数，两位
      target = target.replace(/^(0{1,})(\d{1,}.*)/, '$2'); // 去除开头的多余的0
      state.form.dec_agent_amount = target;
    };

    // 输入框离焦事件
    const handleBlur = () => {
      if (!(state.form.dec_agent_amount || "").includes(".")) {
        if ((state.form.dec_agent_amount || "") != "") {
          state.form.dec_agent_amount = parseInt(state.form.dec_agent_amount).toFixed(2);
        }
      }
    };

    return {
      ...toRefs(state),
      close,
      handleInput,
      handleBlur
    }
  }
}
</script>

<style lang="scss">
.proconfig-config-dialog {
  width: 600px;
  height: 550px;
}
</style>
